// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS & Lodash
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { get, trim } from 'lodash';
// Auth
import { AuthService } from '../../../../core/auth';
import { GlobalErrorHandler } from '@core/utils';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];
	languageChange$: Observable<string>;

	private unsubscribe = new Subject<void>();

	/**
	 * Component constructor
	 *
	 * @param authService : AuthService
	 * @param router: Router
	 * @param fb: FormBuilder
	 * @param cdr: ChangeDetectorRef
	 * @param globalErrorHandler: GlobalErrorHandler
	 * @param snackBar: MatSnackBar
	 * @param {TranslocoService} translocoService
	 */
	constructor(
		readonly authService: AuthService,
		readonly router: Router,
		readonly fb: FormBuilder,
		readonly cdr: ChangeDetectorRef,
		readonly globalErrorHandler: GlobalErrorHandler,
		private readonly snackBar: MatSnackBar,
		private readonly translocoService: TranslocoService,
		private readonly route: ActivatedRoute
	) {
		// Get Current live language
		this.languageChange$ = this.translocoService.langChanges$;
	}

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		const email = get(this.route.snapshot.queryParams, 'email', '');
		this.forgotPasswordForm = this.fb.group({
			email: [email, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		const email = trim(controls.email.value).toLowerCase();
		this.authService.requestPassword(email).pipe(
			tap((response: any) => {
				if (response && response.success) {
					this.snackBar.open(this.translocoService.translate('paragraph_reset_password_email_sent_to_inbox'), this.translocoService.translate('label_close'), {
						panelClass: ['success-dialog']
					});
					this.router.navigateByUrl(`/${this.translocoService.getActiveLang()}/auth/login`);
				} else {
					this.snackBar.open(this.translocoService.translate('paragraph_the_requested_email_is_not_found'), this.translocoService.translate('label_close'), {
						panelClass: ['danger-dialog']
					});
				}
			}),
			catchError(error => this.globalErrorHandler.handleError(`reset-password-form-submit | ${email}`, error)),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			}),
			takeUntil(this.unsubscribe)
		).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
