<div
  class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
  <!--begin::Content-->
  <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 mt-lg-20 mt-md-10">

    <!-- begin:: Logo -->
    <a routerLink="/" class="mb-12">
      <img [alt]="env.orgId" [src]="env.logo" class="h-{{env.logoSize}}px theme-light-show" />
      <img [alt]="env.orgId" [src]="env.logoDark" class="h-{{env.logoSize}}px theme-dark-show" />
    </a>
    <!-- end:: Logo -->

    <!--begin::Content body-->
    <div class="w-lg-500px bg-body rounded p-xs-5 p-sm-5 p-md-10 p-lg-15 mx-auto w-100">
      <router-outlet></router-outlet>
    </div>
    <!--end::Content body-->
  </div>
  <!--end::Content-->

</div>