@if (languageChange$ | async; as _lang) {
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-30">
  <!--begin::Signin-->
  <div class="w-100">
    <!-- begin:: Heading -->
    <div class="text-center mb-10 mb-lg-20">
      <h1 class="text-dark mb-3" transloco="label_sign_in"></h1>
      <p class="text-gray-400 fs-4" transloco="sentence_enter_your_username_and_password">
      </p>
    </div>
    <!-- end:: Heading -->
    <!--begin::Form-->
    <form class="form" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate">
      <!-- begin:: Email -->
      <div class="form-group">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_email"></mat-label>
          <input matInput type="email" placeholder="{{'label_email' | transloco}}" formControlName="email"
            autocomplete="off" />
          @if (isControlHasError('email', 'required')) {
          <mat-error>
            <strong transloco="label_required_field"></strong>
          </mat-error>
          }
          @if (isControlHasError('email', 'email')) {
          <mat-error>
            <strong transloco="sentence_field_is_not_valid"></strong>
          </mat-error>
          }
          @if (isControlHasError('email', 'minlength')) {
          <mat-error>
            <strong transloco="dynamic_minimum_characters_hint" [translocoParams]="{ value: '3' }"></strong>
          </mat-error>
          }
          @if (isControlHasError('email', 'maxlength')) {
          <mat-error>
            <strong transloco="dynamic_maximum_characters_hint" [translocoParams]="{ value: '320' }"></strong>
          </mat-error>
          }
        </mat-form-field>
      </div>
      <!-- end:: Email -->
      <!-- begin:: Password -->
      <div class="form-group">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_password"></mat-label>
          <input matInput type="password" placeholder="{{'label_password' | transloco}}" formControlName="password"
            autocomplete="off" />
          @if (isControlHasError('password', 'required')) {
          <mat-error>
            <strong transloco="label_required_field"></strong>
          </mat-error>
          }
          @if (isControlHasError('password', 'minlength')) {
          <mat-error>
            <strong transloco="dynamic_minimum_characters_hint" [translocoParams]="{ value: '3' }"></strong>
          </mat-error>
          }
          @if (isControlHasError('password', 'maxlength')) {
          <mat-error>
            <strong transloco="dynamic_maximum_characters_hint" [translocoParams]="{ value: '100' }"></strong>
          </mat-error>
          }
        </mat-form-field>
      </div>
      <!-- end:: Password -->
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
        <a href="javascript:;" routerLink="/{{_lang}}/auth/forgot-password"
          class="text-dark-50 text-hover-primary my-3 me-2" transloco="label_forgot_password"></a>
        <button [disabled]="loading" (click)="submit()" class="btn btn-primary px-9 py-4 my-3">
          <span transloco="label_sign_in"></span>
          @if (loading) {
          <span class="spinner-border spinner-border-sm ms-2"></span>
          }
        </button>
      </div>
      <!--end::Action-->
      <!--begin::Separator-->
      <div class="separator separator-content my-14">
        <span class="w-125px text-gray-500 fw-semibold fs-7 text-uppercase" transloco="label_or"></span>
      </div>
      <!--end::Separator-->
      <a title="{{'label_back_to_home_page' | transloco}}" rel="follow" [routerLink]="['/',_lang]"
        class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5 cursor-pointer">
        <i class="fas fa-home"></i>
        <span transloco="label_back_to_home_page"></span>
      </a>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
}