// Angular
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS & Lodash
import { catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { find, get } from 'lodash';
// Auth
import { AuthService, Login } from '../../../../core/auth';
import { CommonService, GlobalErrorHandler, SentryService, SeoService } from '@core/utils';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Models
import { IUserAccount, IUserLogin } from 'src/app/core/auth/_models/user.model';
// Service
import { LayoutService } from '../../layout/services';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Constants
import { LOCALES } from '@constants';

@Component({
	selector: 'app-prime-login-as-user',
	templateUrl: './prime-login-as-user.component.html',
	styleUrls: ['./prime-login-as-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimeLoginAsUserComponent implements OnInit, OnDestroy {

	// Public params
	loginToken: string | null;
	userName: string | null;

	// Subscriptions
	private subscriptions: Subscription[] = [];
	private timmers: Array<null | ReturnType<typeof setTimeout>> = [];

	/**
	 * Creates an instance of PrimeLoginAsUserComponent.
	 * @param {AuthService} authService
	 * @param {Router} router
	 * @param {GlobalErrorHandler} globalErrorHandler
	 * @param {ActivatedRoute} route
	 * @param {SentryService} sentryService
	 * @param {MatSnackBar} snackBar
	 * @param {TranslocoService} translocoService
	 * @param {LayoutService} layoutService
	 * @param {CommonService} cs
	 * @param {Store<AppState>} store
	 * @param {Object} platformId
	 * @memberof PrimeLoginAsUserComponent
	 */
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
		private readonly globalErrorHandler: GlobalErrorHandler,
		private readonly route: ActivatedRoute,
		private readonly sentryService: SentryService,
		private readonly snackBar: MatSnackBar,
		private readonly translocoService: TranslocoService,
		private readonly layoutService: LayoutService,
		private readonly cs: CommonService,
		private readonly store: Store<AppState>,
		private readonly seoService: SeoService,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
		// noindex, nofollow
		this.seoService.setRobotsTag(false);

		if (isPlatformBrowser(this.platformId)) {
			this.loginToken = this.route.snapshot.queryParamMap.get('token');
			this.userName = this.route.snapshot.queryParamMap.get('name');
			this.cs.clearAll();
			this.sentryService.resetAll();
			const delayLoginUserTimmer = setTimeout(() => {
				const loginData: IUserLogin = {
					username: null,
					password: null,
					company_id: null,
					company_default: null
				};
				const headers = { 'Authorization': `Bearer ${this.loginToken}` };
				this.authService.login(loginData, headers).pipe(
					catchError(error => this.globalErrorHandler.handleError(`prime-login-as-user`, error)),
				).subscribe((response) => this.handleLoginResponse(response));
			}, 1000);
			this.timmers.push(delayLoginUserTimmer);
		}
	}

	/**
	 * OnDestroy Lifecyle Hook
	 * @memberof PrimeLoginAsUserComponent
	 */
	ngOnDestroy(): void {
		this.timmers.forEach((timmer: any) => clearTimeout(timmer));
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Handle Login Response
	 * @param response: any
	 * @returns void
	 */
	private handleLoginResponse(response: any): void {
		if (response && response.success) {
			// Set User into Store
			this.store.dispatch(new Login({ user: response?.data, authToken: response?.data?.token?.legacy }));
			// Set User Environment
			this.setUserEnvironmentSettings(response?.data);
		} else {
			this.snackBar.open(this.translocoService.translate('sentence_something_went_wrong'), this.translocoService.translate('label_close'), {
				panelClass: ['danger-dialog']
			});
		}
	}

	/**
	 * Set Logged In User Environment Settings
	 * @private
	 * @param {*} userInfo
	 */
	private setUserEnvironmentSettings(userInfo: IUserAccount) {
		// Set customer type
		this.layoutService.setCustomerType((get(userInfo, 'address.context.user_type', 1)).toString());

		// Set customer country
		const customerCountry = this.cs.getUserLocale(get(userInfo, 'environment.country_id', ''));
		this.layoutService.setCustomerCountry(customerCountry);

		// Set customer currency
		this.layoutService.setCustomerCurrency(get(userInfo, 'environment.currency', 'SEK'));

		// Set cusomer language
		const customerLanguage = find(LOCALES, (l) => l.id == get(userInfo, 'environment.language', 752));
		this.layoutService.setCustomerLanguage(customerLanguage);

		// Redirect logic after login
		this.router.navigate(['/', customerLanguage?.locale])
	}

}
